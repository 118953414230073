.mb-bio{
  padding: 40px 0;
  .mb-bio-content{
    max-width: 272px;
    gap: 24px;
    img{
      width: 100%;
      height: 272px;
      object-fit: cover;
    }
    h2.mb-bio-name{
      font-size: 28px;
      line-height: 34px;
      margin-bottom: 0;
    }
    p.mb-bio-desc{
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 0;
    }
  }
}
