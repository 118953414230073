.artist-content{
  display: flex;
  padding: 24px 56px 116px;
  flex-direction: column;
  width: 100%;
  .ac-setting{
    display: flex;
    align-items: center;
    gap: 24px;
    .btn-follow{
      height: 40px;
      padding: 0 54px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
    }
    .ac-setting-icon{
      width: 40px;
      height: 40px;
    }
  }
  .ac-discography{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .ac-disco-title{
      font-size: 24px;
      line-height: 29px;
    }
  }
}
