:root{
  --mv-primary: #70CFED
}
@import "fonts";
@import "~bootstrap/scss/bootstrap";
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.8.1/css/all.min.css');
@import "modal";
@import "button";
@import "form";
body {
  font-family: 'Myriad Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  color: #ffffff;
}
.main-wrapper{
  display: flex;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  .sidebar{
    width: 20.625vw;
    display: flex;
    flex-direction: column;
  }
  .main-content{
    width: 100%;
    position: relative;
    height: 100%;
  }
  .main-container{
    padding: 116px 56px 0;
    padding-bottom: 232px;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    background: #000000 linear-gradient(180deg, #19407F 0%, #01001A 60.42%) no-repeat;
    position: relative;

  }
  .page-title{
    font-weight: 400;
    font-size: 40px;
    line-height: 48px;
    display: flex;
    align-items: flex-end;
    color: #FFFFFF;
    margin: 0;
  }
  .edge-infinite{
    opacity: 0;
    display: flex;
    width: 100%;
    height: 1px;
    background: #ffffff;
    bottom: 0;
    margin-top: -40px;
  }
  .main-container-full{
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    background: #000000 linear-gradient(180deg, #19407F 0%, #01001A 60.42%) no-repeat;
    position: relative;
    .header-content{
      display: flex;
      width: 100%;
      height: 308px;
      padding:116px 56px 27px;
      color: #ffffff;
      position: relative;
      &:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: inherit;
        background: rgba(112, 207, 237, 0.13);
        filter: blur(20px);
        border-radius: 0;
      }
      .hc-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: inherit;
        img.single-bg{
          width: 100%;
          height: 100%;
          object-fit: cover;
          filter: blur(20px);
        }
        .multi-bg{
          width: 100%;
          height: 100%;
          display: flex;
          flex-wrap: wrap;
          filter: blur(20px);
          img{
            width: 50%;
            height: 50%;
            object-fit: cover;
            &:nth-child(1){
              border-top-left-radius: 10px;
            }
            &:nth-child(2){
              border-top-right-radius: 10px;
            }
            &:nth-child(3){
              border-bottom-left-radius: 10px;
            }
            &:nth-child(4){
              border-bottom-right-radius: 10px;
            }
          }
        }
      }
      .hc-container{
        position: relative;
        z-index: 1;
        gap: 40px;
        .hc-cover-container{
          width: 180px;
          height: 180px;
          border-radius: 10px;
          background: #000000;
          .hc-cover{
            width: 100%;
            height: 100%;
            border-radius: 10px;
            object-fit: cover;
          }
          .hc-cover-multi{
            width: 100%;
            height: 100%;
            border-radius: 10px;
            display: flex;
            flex-wrap: wrap;
            img{
              width: 50%;
              height: 50%;
              &:nth-child(1){
                border-top-left-radius: 10px;
              }
              &:nth-child(2){
                border-top-right-radius: 10px;
              }
              &:nth-child(3){
                border-bottom-left-radius: 10px;
              }
              &:nth-child(4){
                border-bottom-right-radius: 10px;
              }
            }
          }
        }
        .hc-info{
          .hc-category{
            margin-bottom: 4px;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: var(--mv-primary);
          }
          .hc-title{
            font-size: 40px;
            line-height: 48px;
            margin-bottom: 9px;
          }
          .hc-img-user{
            width: 24px;
            height: 24px;
            border-radius: 50%;
          }
          .hc-user{
            gap: 4px;
            .hc-info-2{
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
              color: #FFFFFF;
              margin-right: 6px;
            }
            .fa-circle{
              font-size: 6px;
              line-height: 0;
              color: #FFFFFF;
              margin-right: 6px;
            }
          }
        }
      }
    }
    .header-content-artist {
      display: flex;
      width: 100%;
      height: 308px;
      padding: 116px 56px 27px;
      color: #ffffff;
      position: relative;
      flex: none;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: inherit;
        background: rgba(112, 207, 237, 0.13);
        filter: blur(20px);
        border-radius: 0;
      }

      .hca-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: inherit;

        img.single-bg {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
          filter: blur(20px);
        }
      }
      .hca-container{
        position: relative;
        z-index: 1;
        .hca-content{
          width: 59vw;
          .hca-name{
            font-size: 40px;
            line-height: 48px;
          }
          .hca-bio{
            margin-bottom: 0;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-size: 16px;
            line-height: 19px;
          }
          &>div{
            cursor: pointer;
          }
        }
      }
    }
  }
}
.block-ui-container{
  z-index: 1056!important;
}
.text-primary{
  color: var(--mv-primary) !important;
}
.fs-7{
  font-size: .75em;
}
.icon-dd-small{
  width: 16px;
  height: 16px;
}
.icon-dd-medium{
  width: 24px;
  height: 24px;
}
.icon-dd-large{
  width: 32px;
  height: 32px;
}
.cursor-pointer{
  cursor: pointer !important;
}

