.btn{
  backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 10px 64px;
  &.btn-primary{
    background: rgba(112, 207, 237, 0.13);
    border: 1px solid var(--mv-primary);
  }
}
.btn-white{
  border: 1px solid #ffffff;
  outline: none;
  backdrop-filter: blur(10px);
  border-radius: 10px;
  background: rgba(116, 116, 128, 0.08);
  padding: 9px 20px;
  gap: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 12px;
  line-height: 14px;
}
