.sidebar{
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  background: rgba(11, 8, 13, 0.42);
  border-radius: 0px;
  position: relative;
  &:before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(180deg, #19407F 0%, #01001A 60.42%), #000000;
  }
  .logo{
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 16px;
    margin-bottom: 60px;
    img{
      height: 32px;
      width: auto;
    }
  }
  .sidebar-nav{
    display: flex;
    flex-direction: column;
    width: 100%;
    .sidebar-nav-title{
      font-size: 16px;
      line-height: 19px;
      color: #FFFFFF;
      padding: 8px 0
    }
    ul.sb-nav-list{
      list-style: none;
      display: flex;
      flex-direction: column;
      padding: 0;
      margin:0;
      width: 100%;
      li{
        display: flex;
        cursor: pointer;
        gap: 16px;
        padding: 12px 0;
        align-items: center;
        .icon{
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg{
            width: 24px;
            height: 24px;
            fill: #FFFFFF;
            path{
              fill: #FFFFFF;
            }
          }
        }
        span.nav-label{
          font-size: 20px;
          line-height: 24px;
          color: #FFFFFF;
        }
        &.active{
          span.nav-label{
            color: var(--mv-primary);
          }
          .icon{
            svg{
              fill: var(--mv-primary);
              path{
                fill: var(--mv-primary);
              }
            }
          }
        }
      }
    }
  }
}
