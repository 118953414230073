.content-box{
  display: flex;
  flex-direction: column;
  width: 148px;
  .cb-img{
    width: 100%;
    height: 148px;
    border-radius: 10px;
    margin-bottom: 16px;
    position: relative;
    object-fit: cover;
    cursor: pointer;
    background: #000000;
    img.img-single{
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
    .img-multi{
      width: 100%;
      height: 100%;
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      img{
        width: 50%;
        height: 50%;
        &:nth-child(1){
          border-top-left-radius: 10px;
        }
        &:nth-child(2){
          border-top-right-radius: 10px;
        }
        &:nth-child(3){
          border-bottom-left-radius: 10px;
        }
        &:nth-child(4){
          border-bottom-right-radius: 10px;
        }
      }
    }
    &:before{
      display: none;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
      border-radius: 10px;
      z-index: 1;
      cursor: pointer;
    }
    .play-icon{
      display: none;
      width: 44px;
      height: 44px;
      position: absolute;
      right: 16px;
      bottom: 16px;
      z-index: 2;
      cursor: pointer;
    }
    &.play-overlay:hover{
      &:before{
        display: block;
      }
      .play-icon{
        display: block;
      }
    }
    &.circle{
      border-radius: 50%;
      img.img-single{
        border-radius: 50%;
      }
      &:before{
        border-radius: 50%;
      }
    }
  }
  .cb-title{
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .cb-subtitle{
    margin-top: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--mv-primary);
  }
  &.create-playlist{
    cursor: pointer;
    .cb-plus{
      display: flex;
      height: 148px;
      border-radius: 10px;
      margin-bottom: 16px;
      position: relative;
      background: #A8DBF0;
      align-items: center;
      justify-content: center;
      i.fa{
        color: #ffffff;
        font-size: 40px;
      }
    }

  }
}
