.search-playlist{
  background: rgba(120, 120, 128, 0.36);
  border-radius: 0;
  border: none;
  height: 40px;
  width: 100%;
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  outline: none;
  padding: 0 12px;
  &:focus,&:active{
    border: none;
    outline: none;
  }
}
.list-add-playlist{
  width: 100%;
  border-radius: 0;
  .list-group-item{
    background: transparent;
    border-left: none;
    border-top: none;
    border-right: none;
    border-color: #ffffff;
    color: #ffffff;
    border-radius: 0;
    text-align: left;
    padding: 14px 0;
    &.active-state{
      background: rgba(0,0,0,0.38);
    }
  }
}
