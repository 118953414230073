.cd-nav{
  .nav-item{
    margin-right: 28px;
    .nav-link{
      color: #ffffff;
      padding-left: 0;
      padding-right: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      cursor: pointer;
    }
    &.active{
      .nav-link{
        font-weight: 700;
      }
      border-bottom: 2px solid var(--mv-primary);
    }
  }
}
.mb-playlist{
  padding: 24px 0 40px;
  .mb-playlist-content{
    max-width: 270px;
    .mb-playlist-title{
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      margin-bottom: 24px;
    }
    .mb-playlist-form{
      margin-bottom: 24px;
      .mb-playlist-input{
        border: none;
        width: 100%;
        border-bottom: 1px solid rgba(235, 235, 245, 0.18);
        background: transparent;
        outline: none;
        color: #ffffff;
        font-size: 46px;
        &:focus, &:active{
          border: none;
          border-bottom: 1px solid rgba(235, 235, 245, 0.18);
          outline: none;
          box-shadow: none;
        }
      }
    }
    .mb-playlist-action{
      gap: 8px;
      button{
        width: 131px;
        display: flex;
        justify-content: center;
        padding: 9px 0;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }

}
