.main-mobile{
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  .mobile-content{
    height: 50vh;
    padding: 13vw 9vw 0;
    overflow-y: hidden;
    position: relative;
    background: #1E90FF;
    opacity: 0.56;
    -webkit-filter: blur(12px);
    width: 200vw;
    margin-left: -50vw;
    border-radius: 50%;
  }
  .mobile-footer{
    width: 100%;
    height: 50vh;
    background-color: #FFFFFF;
    .mf-content{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 80%;
      padding: 24px;
      .logo-small{
        height: 16px;
        width: auto;
        margin-bottom: 18px;
      }
      .mf-desc-big{
        font-weight: 700;
        font-size: 20px;
        line-height: 100%;
        text-align: center;
        color: #000000;
        margin-bottom: 8px;
      }
      .mf-desc-small{
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        text-align: center;
        color: #000000;
        margin-bottom: 16px;
      }
      .btn-download-mobile{
        background: rgba(112, 207, 237, 0.13);
        border: 1px solid #70CFED;
        backdrop-filter: blur(10px);
        border-radius: 10px;
        padding: 9px 27px;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #2C6CB5;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
  .mobile-image{
    position: absolute;
    left: 0;
    width: 100vw;
    display: flex;
    justify-content: center;
    height: 60vh;
    bottom: 0;
    .img-apps{
      width: 72.5vw;
      position: absolute;
    }
  }

}
