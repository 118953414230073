.modal-dialog{
  margin-top:10%
}
.modal-header{
  border-bottom: none;
}
.modal-content{
  background-color: #48484A;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.12);
  border-radius: 16px 16px;
}
.modal-body{
  color: #ffffff;
}
.modal-premium{
  .modal-dialog{
    max-width: unset;
    width: 864px;
  }
}
.modal-login{
  .modal-dialog{
    max-width: unset;
    width: 607px;
  }
  .modal-body{
    display: flex;
    justify-content: center;
  }
}
.modal-playlist{
  .modal-dialog{
    max-width: unset;
    width: 526px;
  }
}

.modal-bio{
  .modal-dialog{
    max-width: unset;
    width: 530px;
  }
}

.modal-add-playlist{
  .modal-dialog{
    max-width: unset;
    width: 300px;
    //.mb-add-playlist{
    //  height: 350px;
    //  overflow-y: auto;
    //}
  }
}
