.search-form{
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: 40px;
  input.search-input{
    background: rgba(120, 120, 128, 0.36);
    border-radius: 5px;
    border: none;
    height: 64px;
    width: 100%;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    padding: 0 24px 0 64px;
    outline: none;
    border-radius: 5px;
    &:focus,&:active{
      border: none;
      outline: none;
    }
  }
  i.fa{
    position: absolute;
    left: 19px;
    top:18px;
    color: #8E8E93;
    font-size: 24px;
  }
}
.content-search{
  display: flex;
  flex-direction: column;
  .cs-title{
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    margin-bottom: 24px;
  }
  .category-list{
    display: flex;
    flex-wrap: wrap;
    column-gap: 23.7px;
    row-gap: 40px;
    .category-item{
      height: 145px;
      width: 282.8688659667969px;
      display: flex;
      position: relative;
      cursor: pointer;
      .img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .category-name{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        span{
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: #FFFFFF;
          text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
  .search-list{
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 51px;
  }
  .search-see-all{
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    .ssa-item{
      padding: 18.5px 0;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      .ssa-label{
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #FFFFFF;
      }
      i.fa{
        color: #FFFFFF;
        font-size: 18px;
      }
    }
  }
  .empty-search{
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 88px;
    .es-content{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      h3{
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 34px;
        color: #FFFFFF;
        margin-bottom: 2px;
      }
      p{
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
      }
    }
  }
}
