.sign-up{
  background: linear-gradient(180deg, #19407F 0%, #01001A 60.42%), #000000;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;
  align-items: center;
  padding-bottom: 55px;
  .page-header{
    display: flex;
    width: 100%;
    justify-content: center;
    height: 80px;
    align-items: center;
    position: relative;
    h3{
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;;
      color: #FFFFFF;
    }
    .back{
      cursor: pointer;
      position: absolute;
      top: 28px;
      left: 85px;
      i{
        font-size: 16px;
        color: #ffffff;
      }
    }
    margin-bottom: 64px;
  }
  .sign-up-form{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 540px;
    align-items: center;
    .avatar{
      display: flex;
      flex-direction: column;
      align-items: center;
      img{
        width: 128px;
        height: 128px;
        margin-bottom: 19px;
        border-radius: 50%;
      }
    }
  }
}
.btn-save-profile{
  width: 356px;
}
.avatar-file{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  display: flex;
}

