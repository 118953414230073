.menu-dropdown{
  text-decoration: none;
}
.menu-dropdown-content{
  display: flex;
  align-items: center;
  padding: 0 16px;
  gap: 24px;
  background: rgba(112, 207, 237, 0.13);
  border-radius: 999px;
  height: 46px;
  .user-info{
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    display: flex;
    gap: 11px;
    align-items: center;
    i.far{
      color: #ffffff;
      font-size: 16px;
    }
  }
  i.fa{
    color: #ffffff;
    font-size: 14px;
  }
}
.menu-nav-content{
  background: #48484A;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  .dropdown-item{
    color: #ffffff;
    font-size: 16px;
    line-height: 19px;
    padding: 10px;
    &.active,&:hover,&:focus{
      background: rgba(168, 219, 240, 0.2);
    }

  }
}
