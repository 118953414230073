.nf-desc-big{
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
}
.nf-desc-small{
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
}
