.mb-premium{
  gap:64px;
  padding: 40px 108px;
  .mb-premium-thumb{
    width: 272px;
    height: 272px;
  }
  .mb-premium-content{
    .mb-premium-content-title{
      font-size: 32px;
      line-height: 38px;
      text-align: center;
      color: #FFFFFF;
      margin-bottom: 40px;
    }
    .w-181px{
      width: 181px;
      display: flex;
      justify-content: center;
      padding-right: 0;
      padding-left: 0;
    }

  }
}
