.search-song-playlist{
  display: flex;
  flex-direction: column;
  margin-top: 52px;
  width: 100%;
  .ssp-header{
    margin-bottom: 16px;
    .ssp-title{
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 0;
    }
    .fa{
      font-size: 20px;
    }
  }
  .ssp-content{
    gap: 8px;
    .ssp-content-item{
      .sspc-item-info{
        gap: 16px;
        .sspc-info-image{
          width: 48px;
          height: 48px;
          border-radius: 10px;
        }
        .sspc-info-name{
          font-size: 16px;
          line-height: 19px;
          margin-bottom: 4px;
        }
        .sspc-info-artist{
          font-size: 12px;
          line-height: 14px;
          color: var(--mv-primary);
        }
      }
      .sspc-item-action{
        width: 22px;
        height: 22px;
        cursor: pointer;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
