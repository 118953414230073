.header{
  display: flex;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  padding: 10px 24px 10px 16px;
  justify-content: space-between;
  align-items: center;
  .header-left{
    display: flex;
    align-items: center;
    gap: 8px;
    .header-nav{
      width: 44px;
      height: 44px;
      background: #3A3A3C;
      border-radius: 999px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      i.fa{
        color: #FFFFFF;
        font-size: 24px;
      }
    }
  }
  .header-right{
    display: flex;
    align-items: center;
    gap: 24px;
    button.premium{
      background: transparent;
      outline: none;
      box-shadow: none;
      display: flex;
      align-items: center;
      padding: 10px 24px;
      gap: 16px;
      border: 1px solid #FFFFFF;
      border-radius: 999px;
      color: #ffffff;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      img{
        width: 24px;
        height: 24px;
      }
    }
  }
}
