.page-title{
  margin-bottom: 40px !important;
}
.timeline-list{
  gap: 40px;
  &.single{
    .tl-items{
      margin-bottom: 116px;
    }
  }
  .tl-items{
    display: flex;
    flex-direction: column;
    width: 100%;
    .tl-items-header{
      display: flex;
      justify-content: space-between;
      margin-bottom: 34px;
      align-items: flex-end;
      .tl-title{
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        color: #FFFFFF;
      }
      .tl-more{
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
        text-decoration: none;
        cursor: pointer;
      }
    }

    .tl-content{
      display: flex;
      flex-wrap: wrap;
      gap: 56px;
      max-height: 201px;
      overflow: hidden;
      &.loskeun{
        max-height: unset;
        overflow: auto;
      }
    }
  }
}
.mb-premium{
  gap:64px;
  padding: 40px 108px;
  .mb-premium-thumb{
    width: 272px;
    height: 272px;
  }
  .mb-premium-content{
    .mb-premium-content-title{
      font-size: 32px;
      line-height: 38px;
      text-align: center;
      color: #FFFFFF;
      margin-bottom: 40px;
    }
    .w-181px{
      width: 181px;
      display: flex;
      justify-content: center;
      padding-right: 0;
      padding-left: 0;
    }

  }
}
