.ml-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  width: 351px;
  img.logo{
    width: 76px;
    margin-bottom: 72px;
  }
  .ml-title{
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 36px;
  }
  .social{
    display: flex;
    gap: 28px;
    margin-bottom: 24px;
    .social-item{
      width: 48px;
      height: 48px;
      cursor: pointer;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .or{
    font-size: 16px;
    line-height: 19px;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    position: relative;
    margin-bottom: 24px;
    span{
      z-index: 1;
      color: #FFFFFF;
      background: #48484A;
      padding: 0 8px;
    }
    &:before{
      position: absolute;
      content: '';
      background: #ffffff;
      height: 1px;
      width: 100%;
      z-index: 0;
      left: 0;
      bottom: 8px;
    }
  }
  .btn-sign-up-email{
    margin-bottom: 91px;
  }
  .have-account-text{
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
  }
  .btn-login{
    width: 158px;
    padding: 9px 0;
    font-size: 12px;
    line-height: 14px;
  }
  .forgot-pass{
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
  }
  .form-wrapper{
    margin-bottom: 40px;
  }

}
