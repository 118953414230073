.menu-dropdown{
  text-decoration: none;
}
.menu-nav-content{
  background: #48484A;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  .dropdown-item{
    color: #ffffff;
    font-size: 16px;
    line-height: 19px;
    padding: 10px;
    &.active,&:hover,&:focus{
      background: rgba(168, 219, 240, 0.2);
    }

  }
}
