.music-player-wrapper{
  position: fixed;
  left: 0;
  bottom: 0;
  background: rgba(25, 25, 25, 0.61);
  backdrop-filter: blur(4px);
  width: 100%;
  height: 110px;
  z-index: 3;
  .mpw-content{
    width: 100%;
    height: inherit;
    padding: 16px 24px;
    .mpw-artist{
      display: flex;
      gap: 24px;
      align-items: center;
      width: 302px;
      .mpw-artist-img{
        width: 64px;
        height: 64px;
        object-fit: cover;
        border-radius: 10px;
      }
      .mpw-artist-info{
        display: flex;
        flex-direction: column;
        width: 215px;
        .mpw-song-name{
          font-size: 20px;
          line-height: 24px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .mpw-artist-name{
          font-size: 14px;
          line-height: 17px;
          color: var(--mv-primary);
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
    .mpw-player{
      display: flex;
      flex-direction: column;
      gap: 14px;
      align-items: center;
      //width: 684px;
      .mpw-player-control{
        display: flex;
        gap: 38.5px;
        position: relative;
        align-items: center;
        .mpwp-control-shuffle{
          cursor: pointer;
          position: absolute;
          left: 0;
          top: 10px;
          margin-left: -60px;
          i {
            font-size: 24px;
            color: #ffffff;
          }
          &.shuffled{
            i {
              color: #27B535;
            }
          }
        }
        .mpwp-control-nav{
          cursor: pointer;
          i {
            font-size: 16px;
            color: #ffffff;
          }
          &.disabled{
            i {
              color:#999999;
            }
          }
        }
        .mpwp-control-play{
          cursor: pointer;
          img{
            width: 49px;
            height: 49px;
          }
        }
      }
      .mpw-player-duration{
        display: flex;
        gap: 12px;
        align-items: center;
        span{
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
    .mpw-volume{
      display: flex;
      align-items: center;
      gap: 12px;
      .fa-volume-mute{
        color: var(--bs-danger);
      }
    }
  }
}
