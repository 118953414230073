.content-list-container{
  margin-top: 24px;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 40px;
  .cl-setting{
    display: flex;
    align-items: center;
    gap: 24px;
    img.play{
      width: 64px;
      height: 64px;
      cursor: pointer;
    }
    .cl-setting-icon{
      width: 32px;
      height: 32px;
    }
  }
  .cl-contents{
    .table{
      thead tr td{
        border-bottom-width: 1px;
        border-color: rgba(168, 219, 240, 0.2);
        font-size: 12px;
        line-height: 14px;
        color: #999999;
      }
      .cl-items{
        cursor: pointer;
      }
      tbody tr {
        td{
          font-size: 16px;
          line-height: 19px;
          color: #FFFFFF;
          vertical-align: middle;
          &.cl-items-info{
            .cl-info{
              gap: 12px;
              .cl-info-cover{
                width: 48px;
                height: 48px;
                border-radius: 10px;
                object-fit: cover;
              }
              .cl-info-title{
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
              }
              .cl-info-caption{
                font-size: 12px;
                line-height: 14px;
                color: var(--mv-primary);
              }
            }
          }
          .cl-items-action{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .cl-action-icon{
              cursor: pointer;
              width: 16px;
              height: 16px;
              &.liked{
                fill: #FFFFFF;
              }
            }
          }
          .cl-items-playing{
            display: none;
            width: 24px;
            height: 24px;
          }
          .cl-items-play{
            display: none;
            width: 16px;
            height: 16px;
          }
        }
        &:first-child{
          td{
            padding-top: 24px
          }
        }
        &.clicked,&.playing,&:hover{
          td{
            background: rgba(168, 219, 240, 0.2);
            &:first-child{
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
            }
            &:last-child{
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
            }
            .cl-items-no{
              display: none;
            }
          }
        }
        &.clicked,&:hover{
          td{
            .cl-items-playing{
              display: none;
            }
            .cl-items-play{
              display: block;
            }
          }
        }
        &.playing{
          td{
            .cl-items-playing{
              display: block !important;
            }
            .cl-items-play{
              display: none !important;
            }
          }
        }
      }
    }
  }
}
