/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'Myriad Pro';
    font-style: normal;
    font-weight: 500;
    src: local('Myriad Pro Regular'), url('MyriadPro-Bold.otf') format('woff');
    }

    @font-face {
    font-family: 'Myriad Pro';
    font-style: normal;
    font-weight: 400;
    src: local('Myriad Pro Light'), url('MyriadPro-Regular.otf') format('opentype');
    }


    @font-face {
    font-family: 'Myriad Pro';
    font-style: normal;
    font-weight: 600;
    src: local('Myriad Pro Semibold'), url('MyriadPro-Semibold.otf') format('opentype');
    }

    @font-face {
    font-family: 'Myriad Pro';
    font-style: normal;
    font-weight: 700;
    src: local('Myriad Pro Bold'), url('MyriadPro-Bold.otf') format('opentype');
    }

