.form-wrapper{
  width: 100%;
}
.input-group-icon{
  position: relative;
  i{
    position: absolute;
    top: 1rem;
  }
  .icon{
    left: 11px;
  }
  &.icon-right{
    .icon{
      right: 11px;
      left: unset;
    }
  }
}

.input-group-icon{
  i.icon{
    color: #ffffff;
  }
}
select.form-control, input.form-control{
  background: rgba(120, 120, 128, 0.36);
  border-radius: 10px;
  border-color: transparent;
  padding: 16px 11px;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  &:focus{
    background-color: rgba(120, 120, 128, 0.36);
    border-color: transparent;
    box-shadow: none;
    color: #ffffff;
  }
  &::placeholder{
    font-size: 14px;
    line-height: 17px;
    color: rgba(235, 235, 245, 0.6);
  }
}
select.form-select{
  background-color: rgba(120, 120, 128, 0.36);
  border-radius: 10px;
  border-color: transparent;
  padding: 16px 11px;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  &:focus{
    background-color: rgba(120, 120, 128, 0.36);
    border-color: transparent;
    box-shadow: none;
    color: #ffffff;
  }
  &::placeholder{
    font-size: 14px;
    line-height: 17px;
    color: rgba(235, 235, 245, 0.6);
  }
}
.input-group-icon{
  input.form-control{
    padding-left: 40px;
  }
  &.icon-right{
    input.form-control{
      padding-left: 11px;
      padding-right: 40px;
    }
  }
}
